<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-15 16:46:09
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-22 18:07:30
 * @FilePath: /mediatom-web/src/viewsForManage/mediaDashboard/models/Chart/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <m-ratio-box :ratio="37" class="ratio-box">
    <div class="media-dsshboard-chart" :style="{'font-size': fontSize + 'px'}">
      <div class="head">
        <div class="head-left">数据趋势</div>
        <div class="head-right">
          <a-range-picker
            style="width: calc(243em / 14);margin-right: calc(10em / 14)"
            format="YYYY-MM-DD"
            :placeholder="['Start', 'End']"
            @change="ChangeDate"
            :value="rangePickerDate"
            :allowClear="false"
            :ranges="{
              今日: [initDate(0, 0).start, initDate(0, 0).end],
              昨日: [initDate(1, 1).start, initDate(1, 1).end],
              最近一周: [initDate(6, 0).start, initDate(6, 0).end],
              最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
            }"
            :disabledDate="disabledDate"
          >
          </a-range-picker>
          <a-select v-model="target" style="width: calc(130em / 14)">
            <a-select-option v-for="item in targets" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </div>
      </div>
      <div class="chart">
        <MediaChart ref="chart" :target="target"/>
      </div>
    </div>
  </m-ratio-box>
</template>

<script>
import mixDate from '@/mixins/initDate'
import autoFontSize from '@/mixins/autoFontSize'
import { getDuration } from '@/utils/duration'
import { MediaChart, targets } from '@/components/MediaChart'
export default {
  name: 'Chart',
  components: { MediaChart },
  mixins: [mixDate, autoFontSize],
  data () {
    return {
      searchquery: {
        dateStart: undefined,
        dateEnd: undefined,
        appIdList: [],
        placeIdList: [],
        positionIdList: [],
        osList: []
      },
      target: targets[0].id,
      targets
    }
  },
  created () {
    this.initTime()
    this.handleSearch()
  },
  methods: {
    // 初始化时间
    initTime () {
      const { endDate, startDate, end, start } = this.initDate(7, 1)
      this.rangePickerDate = [start, end]
      this.searchquery.dateEnd = endDate
      this.searchquery.dateStart = startDate
    },
    // 时间修改回调
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.handleSearch()
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    handleSearch () {
      this.$nextTick(() => {
        this.$refs.chart.getChartData(this.searchquery)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.ratio-box{
  margin-top: 2em;
}
.media-dsshboard-chart{
  background-color: #fff;
  border-radius: calc(10em / 14);
  height: calc(504em / 14);
  padding: calc(20em / 14);
  display: flex;
  flex-direction: column;
  .head{
    display: flex;
    padding: 0 calc(10em / 14);
    justify-content: space-between;
    align-items: center;
    .head-left{
      padding-left: calc(15em / 14);
      position: relative;
      font-size: calc(18em / 14);
      line-height: calc(25em / 14);
      font-weight: bold;
      &::after{
        content: '';
        width: calc(6em / 14);
        height: calc(12em / 14);
        background-color: #6554c0;
        position: absolute;
        left: 0;
        top: calc(50% - 10em / 14);
      }
    }
    .head-right{
      display: flex;
      align-items: center;
    }
  }
  .chart{
    flex: 1 auto;
    height: 0;
  }
}
</style>
